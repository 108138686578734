import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertController, NavController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-cuenta-update',
  templateUrl: './cuenta-update.page.html',
  styleUrls: ['./cuenta-update.page.scss'],
})
export class CuentaUpdatePage implements OnInit {

  ionicForm: FormGroup;
  isSubmitted = false;
  usuario:any = {};
  

  constructor( public alertController:AlertController, private _api:ApiService, public formBuilder: FormBuilder,
              public navCtrl:NavController ) { 
    this.ionicForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],                    
      nombre: ['', [Validators.required, Validators.minLength(3)]],   
      usuario: ['', []],           
    });

  }

  ngOnInit() {
    this.getCuenta();
  }

  async presentAlertPrompt() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Cambiar contraseña',
      inputs: [
        {
          name: 'pass_actual',
          type: 'password',
          placeholder: 'Contraseña actual'
        },
        {
          name: 'pass',
          type: 'password',          
          placeholder: 'Nueva contraseña'
        },
        {
          name: 'pass_new',
          type: 'password',          
          placeholder: 'Confirmar nueva contraseña'
        }           
      ],
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel');
          }
        }, {
          text: 'Guardar',
          handler: (data:any) => {
            console.log('Confirm Ok', data);

            if( !data.pass_actual ){
              return this.presentAlert("La contraseña actual es requerida");
            }

            if( data.pass !== data.pass_actual ){
              return this.presentAlert("La contraseña y confirmar contraseña no coinciden");
            }

            let data_send = { id: this.usuario._id, email: this.usuario.email, nombre: this.usuario.nombre, pass: data.pass };

            this._api.updateUser(data_send).subscribe((data:any)=>{
              if( data.status == 1 ){
                this.presentAlert("La contraseña se actualizó correctamente.");
              }else{
                this.presentAlert(data.mesaje);
              }
            });

          }
        }
      ]
    });

    await alert.present();
  }

  getCuenta(){
    let user = localStorage.getItem("user");
    this._api.getUser({id:user}).subscribe((data:any)=>{
      console.log(data);
      this.usuario = data.data;
    });
  }

  submitForm() {
    this.isSubmitted = true;
    console.log(this.ionicForm.valid);
    if (!this.ionicForm.valid) {
      // console.log('Please provide all the required values!')
      return false;
    } else {
      this.ionicForm.value.id = this.usuario._id;
      console.log(this.ionicForm.value);        
      this._api.updateUser(this.ionicForm.value).subscribe((data:any)=>{
        if( data.status ){
          this.presentAlert("El usuario se actualizó correctamente.");
          this.navCtrl.back();
        }else{
          this.presentAlert(data.mesaje);
        }
      });
    }
  }

  async presentAlert( mensaje:string ) {
    const alert = await this.alertController.create({      
      subHeader: 'Mensaje',
      message: mensaje,
      buttons: ['OK'],
    });

    await alert.present();
  }

}
